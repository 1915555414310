<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M6.75 0C5.82187 0 5.0625 0.759375 5.0625 1.6875V25.3125C5.0625 26.2406 5.82187 27 6.75 27H23.625C24.5531 27 25.3125 26.2406 25.3125 25.3125V6.75L18.5625 0H6.75Z" fill="#E2E5E7"/>
    <path d="M20.25 6.75H25.3125L18.5625 0V5.0625C18.5625 5.99062 19.3219 6.75 20.25 6.75Z" fill="#B0B7BD"/>
    <path d="M25.3125 11.8125L20.25 6.75H25.3125V11.8125Z" fill="#CAD1D8"/>
    <path d="M21.9375 21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812H2.53125C2.06719 22.7812 1.6875 22.4016 1.6875 21.9375V13.5C1.6875 13.0359 2.06719 12.6562 2.53125 12.6562H21.0938C21.5578 12.6562 21.9375 13.0359 21.9375 13.5V21.9375Z" fill="#F15642"/>
    <path d="M21.0938 22.7812H5.0625V23.625H21.0938C21.5578 23.625 21.9375 23.2453 21.9375 22.7812V21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812Z" fill="#CB4424"/>
    </g>
    <path d="M5.98001 20.042C5.84468 20.042 5.73501 20.0023 5.65101 19.923C5.57168 19.839 5.53201 19.727 5.53201 19.587V15.492C5.53201 15.3567 5.56935 15.2517 5.64401 15.177C5.72335 15.1023 5.83068 15.065 5.96601 15.065H7.68801C8.21535 15.065 8.62601 15.198 8.92001 15.464C9.21401 15.7253 9.36101 16.094 9.36101 16.57C9.36101 17.046 9.21401 17.417 8.92001 17.683C8.62601 17.949 8.21535 18.082 7.68801 18.082H6.43501V19.587C6.43501 19.727 6.39301 19.839 6.30901 19.923C6.22968 20.0023 6.12001 20.042 5.98001 20.042ZM7.57601 17.389C8.19668 17.389 8.50701 17.1183 8.50701 16.577C8.50701 16.0357 8.19668 15.765 7.57601 15.765H6.43501V17.389H7.57601Z" fill="#FBFCFF"/>
    <path d="M10.6711 20.042C10.5358 20.042 10.4261 20.0023 10.3421 19.923C10.2628 19.839 10.2231 19.727 10.2231 19.587V15.492C10.2231 15.3567 10.2604 15.2517 10.3351 15.177C10.4144 15.1023 10.5218 15.065 10.6571 15.065H12.3791C12.9064 15.065 13.3171 15.198 13.6111 15.464C13.9051 15.7253 14.0521 16.094 14.0521 16.57C14.0521 17.046 13.9051 17.417 13.6111 17.683C13.3171 17.949 12.9064 18.082 12.3791 18.082H11.1261V19.587C11.1261 19.727 11.0841 19.839 11.0001 19.923C10.9208 20.0023 10.8111 20.042 10.6711 20.042ZM12.2671 17.389C12.8878 17.389 13.1981 17.1183 13.1981 16.577C13.1981 16.0357 12.8878 15.765 12.2671 15.765H11.1261V17.389H12.2671Z" fill="#FBFCFF"/>
    <path d="M16.415 20.035C16.275 20.035 16.163 19.9953 16.079 19.916C15.9997 19.832 15.96 19.7223 15.96 19.587V15.807H14.686C14.406 15.807 14.266 15.6833 14.266 15.436C14.266 15.1887 14.406 15.065 14.686 15.065H18.137C18.417 15.065 18.557 15.1887 18.557 15.436C18.557 15.6833 18.417 15.807 18.137 15.807H16.863V19.587C16.863 19.7223 16.821 19.832 16.737 19.916C16.6577 19.9953 16.5503 20.035 16.415 20.035Z" fill="#FBFCFF"/>
    <defs>
    <clipPath id="clip0">
    <rect width="27" height="27" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>